import store2 from 'store2';

export type LocalStorageType = {
  callbackUrl: string;
  didAbandonSignInOnboardingPage: string;
  didAbandonProfileInputPage: string;
};

export type KEY = keyof LocalStorageType;

export const localStorage = <Key extends KEY>(key: Key) => ({
  getValue: (): LocalStorageType[Key] => store2.get(`klew.${key}`) as LocalStorageType[Key],
  setValue: (value: LocalStorageType[Key]) => store2.set(`klew.${key}`, value),
  removeValue: () => store2.remove(`klew.${key}`),
});
