/**
 * 固定値を保存するタイプのCookieのKey,Valueを扱う定数オブジェクト
 */
export const FIXED_VALUE_COOKIE = {
  COMMUNITY_GUIDELINES_AGREED: {
    KEY: 'COMMUNITY_GUIDELINES_AGREED',
    VALUE: '1',
  },
  /**
   * 通信などの影響で言語設定が取得できない場合に、言語設定をスキップするためのCookie
   */
  KLEW_USER_LANGUAGE_SETTING_SKIP: {
    KEY: 'KLEW_USER_LANGUAGE_SETTING_SKIP',
    VALUE: '1',
  },
  /**
   * 過去のログイン判定
   */
  HAS_LOGGED_IN: {
    KEY: 'HAS_LOGGED_IN',
    VALUE: '1',
  },
} as const satisfies Record<string, { KEY: string; VALUE: string }>;

/**
 * 読み取り専用のCookieのKeyを扱う定数オブジェクト
 */
export const READONLY_COOKIE = {
  SELECTED_ARTIST_OFFICIAL_ACCOUNT_ID: {
    KEY: 'chains_selected_artist_official_account_id',
  },
  KLEW_USER_LANGUAGE_SETTING_SKIP: {
    KEY: 'KLEW_USER_LANGUAGE_SETTING_SKIP',
  },
} as const satisfies Record<string, { KEY: string }>;

export const ONE_DAY_IN_SECONDS = 60 * 60 * 24;

type ValueOf<T extends Record<string, unknown>> = T[keyof T];
export type FixedValueCookieType = keyof typeof FIXED_VALUE_COOKIE;
export type CookieKeyType =
  | ValueOf<typeof FIXED_VALUE_COOKIE>['KEY']
  | ValueOf<typeof READONLY_COOKIE>['KEY'];
