'use client';

import { useLazyQuery } from '@apollo/client';
import { Magic as MagicBase } from 'magic-sdk';
import { useLocale, useTranslations } from 'next-intl';
import { destroyCookie } from 'nookies';
import type { ReactNode } from 'react';
import { createContext, startTransition, useContext, useEffect, useState } from 'react';
import { polygonAmoy, polygon } from 'viem/chains';
import { GetMeIsEmailVerifiedDocument } from '@/__generated__/graphql';
import { PostSignOutAPI, PostSignInAPI } from '@/apis';
import { LoadingInner } from '@/components/ui/loading/Loading';
import { PATHS, QUERIES } from '@/constants/path';
import { usePathname, useRouter } from '@/i18n/routing';
import useDialog from '@/providers/dialog-provider';
import { getCookie, setCookie } from '@/utils/cookie';
import { localStorage } from '@/utils/local-storage';

export type AuthConditions = {
  isEmailVerified: string | null;
  isRegistered: string | null;
  selectedArtistOfficialAccountId: string | null;
};

export type Magic = MagicBase<[]>;

type AuthContextType = {
  isArtistLoggedIn: boolean;
  isEmailVerified: boolean;
  isRegistered: boolean;
  login(): Promise<void>;
  loginDisabled: boolean;
  logout(): Promise<void>;
  magicAuthenticated: boolean;
  magicAuthReady: boolean;
  redirectAfterLoginWithWelcomeModal: () => void;
  selectedArtistOfficialAccountId: string | null;
  setSelectedArtistOfficialAccountId(artistOfficialAccountId: string | null): void;
  token?: string;
  getMagicEmailAddress(): Promise<string | null | undefined>;
};

const network = {
  rpcUrl: (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prd' ? polygon : polygonAmoy).rpcUrls.default
    .http[0],
  chainId: (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prd' ? polygon : polygonAmoy).id,
};
const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuthenticationContext = () => useContext(AuthContext);

function useMagic({ isRegistered, isBot }: { isRegistered: boolean; isBot: boolean }) {
  const [magic, setMagic] = useState<Magic | null>(null);
  const [magicAuthenticated, setMagicAuthenticated] = useState(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [magicAuthReady, setMagicAuthReady] = useState(false);
  const locale = useLocale();

  useEffect(() => {
    (async () => {
      try {
        const magic = new MagicBase(process.env.NEXT_PUBLIC_MAGIC_API_KEY, {
          network,
          locale,
        });
        setMagic(magic);
        const hasLoggedIn = getCookie('HAS_LOGGED_IN');
        if (!isRegistered && !isBot && hasLoggedIn === '1') {
          const magicLogin = await magic.user.isLoggedIn();
          if (magicLogin) {
            setMagicAuthenticated(true);
            const didToken = await magic.user.getIdToken();
            setToken(didToken);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setMagicAuthReady(true);
      }
    })();
  }, [locale]);

  async function magicLogin() {
    try {
      await magic?.wallet.connectWithUI();
      setMagicAuthenticated(true);
      const didToken = await magic?.user.getIdToken();
      setToken(didToken);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async function magicLogout() {
    try {
      await magic?.user.logout();
      setToken('');
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  return {
    magic,
    magicAuthReady,
    magicAuthenticated,
    magicLogin,
    magicLogout,
    token,
  };
}

export const AuthProvider = ({
  children,
  authConditions,
  isBot,
}: {
  children: ReactNode;
  authConditions: AuthConditions;
  isBot: boolean;
}) => {
  const t = useTranslations();
  const pathname = usePathname();
  const { replace, push } = useRouter();
  const [isInitializationComplete, setIsInitializationComplete] = useState(false);
  const [isRegistered, setIsRegistered] = useState(authConditions.isRegistered === 'true');
  const [isEmailVerified, setIsEmailVerified] = useState(authConditions.isEmailVerified === 'true');
  const [getMeIsEmailVerified] = useLazyQuery(GetMeIsEmailVerifiedDocument, {
    onCompleted: (data) => {
      if (data.me?.isEmailVerified) {
        setIsRegistered(true);
        setIsEmailVerified(true);
      }
    },
  });
  const { showDialog } = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedArtistOfficialAccountId, setSelectedArtistOfficialAccountId] = useState(
    authConditions.selectedArtistOfficialAccountId,
  );
  const isArtistLoggedIn = isRegistered && selectedArtistOfficialAccountId !== null;
  const { magicLogin, magicAuthReady, magicLogout, token, magic, magicAuthenticated } = useMagic({
    isRegistered,
    isBot,
  });

  async function getMagicEmailAddress() {
    return (await magic?.user.getInfo())?.email;
  }

  // ログイン後のリダイレクトとウェルカムモーダルの表示を処理
  async function redirectAfterLoginWithWelcomeModal() {
    try {
      const callbackUrl = localStorage('callbackUrl').getValue();
      const { ok, isEmailVerified, isRegistered } = await PostSignInAPI(token);
      if (ok) {
        setIsRegistered(isRegistered);
        setIsEmailVerified(isEmailVerified);
        localStorage('didAbandonProfileInputPage').setValue('false');
      }
      push(
        `${callbackUrl || PATHS.ROOT}?${QUERIES.WELCOME_MODAL.key}=${QUERIES.WELCOME_MODAL.value}`,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function clearStorageOnboardingAndProfile() {
    localStorage('didAbandonProfileInputPage').setValue('false');
  }

  // ログイン処理
  async function login() {
    try {
      setIsLoading(true);
      const domain =
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ? '.dev.klew.jp' : location.hostname;
      destroyCookie(null, 'chains_is_email_verified', { path: '/', domain });
      destroyCookie(null, 'chains_is_registered', { path: '/', domain });
      await magicLogout();
      setIsRegistered(false);
      setIsEmailVerified(false);
      await magicLogin();
      localStorage('callbackUrl').setValue(pathname);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  // ログアウト処理
  async function logout() {
    try {
      const didToken = await magic?.user.getIdToken();
      if (didToken) {
        await PostSignOutAPI(didToken);
        await magicLogout();
        destroyCookie(null, 'HAS_LOGGED_IN', {
          path: '/',
        });
        clearStorageOnboardingAndProfile();
        window.location.assign(process.env.NEXT_PUBLIC_BASE_URL);
      }
    } catch (error) {
      console.error(error);
      showDialog({
        title: t('common-error-title'),
        body: t('login-error-description'),
      });
    }
  }

  // メール認証チェック
  useEffect(() => {
    if (isRegistered) {
      setCookie('HAS_LOGGED_IN', {
        maxAge: 30 * 24 * 60 * 60, // 30日
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
      getMeIsEmailVerified();
    }
  }, [isRegistered]);

  // Magic認証後の処理
  useEffect(() => {
    (async () => {
      const interrupt =
        !isInitializationComplete ||
        isRegistered ||
        !magicAuthReady ||
        !magicAuthenticated ||
        !token;

      if (interrupt) return;
      try {
        const { ok, isEmailVerified, isRegistered } = await PostSignInAPI(token);
        if (ok) {
          startTransition(() => {
            setIsRegistered(isRegistered);
            setIsEmailVerified(isEmailVerified);
          });
          if (!isRegistered) {
            replace(PATHS.SIGN_UP_PROFILE);
          }
        }
      } catch (error) {
        console.error(error);
        showDialog({
          title: t('common-error-title'),
          body: t('login-error-description'),
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [magicAuthenticated, magicAuthReady, isRegistered, token, isInitializationComplete]);

  // ユーザーが登録プロセスを途中離脱した場合の処理
  useEffect(() => {
    (async () => {
      const didAbandonProfileInputPage =
        localStorage('didAbandonProfileInputPage').getValue() === 'true';

      if (magicAuthReady) {
        const urls: string[] = [PATHS.SIGN_UP, PATHS.SIGN_UP_PROFILE];
        const isSignInProcessPage = urls.includes(pathname);
        if (
          !isRegistered &&
          magicAuthenticated &&
          didAbandonProfileInputPage &&
          !isSignInProcessPage
        ) {
          await magicLogout();
          localStorage('didAbandonProfileInputPage').setValue('false');
        }
        setIsInitializationComplete(true);
      }
    })();
  }, [magicAuthReady, pathname]);

  const value = {
    isArtistLoggedIn,
    isEmailVerified,
    isRegistered,
    login,
    loginDisabled: !magicAuthReady || isRegistered || isLoading,
    logout,
    magicAuthenticated,
    magicAuthReady,
    redirectAfterLoginWithWelcomeModal,
    selectedArtistOfficialAccountId,
    setSelectedArtistOfficialAccountId,
    token,
    getMagicEmailAddress,
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoading && (
        <div className='fixed left-0 top-0 z-50 flex h-lvh w-full justify-center'>
          <div className='absolute flex size-full md:relative md:w-md-base lg:w-lg-base'>
            <div className='shrink-0 md:hidden lg:block lg:w-[20rem]' />
            <LoadingInner dark />
          </div>
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};
