import { useTranslations } from 'next-intl';
import { ModalBase } from './ModalBase';
import type { ModalContentType } from './types';
import { Button } from '@/components/ui/button/Button';
import useDialog from '@/providers/dialog-provider';
import { DynamicPortal } from '@/utils/dynamic-portal';

export const DialogPortal = ({ isOpen, props }: { isOpen: boolean; props: ModalContentType }) => {
  const { closeDialog } = useDialog();
  const t = useTranslations();

  const handleClose = () => {
    closeDialog();
    props.handleBackButton?.();
  };

  return (
    <DynamicPortal id='errorDialogPortal'>
      <ModalBase
        isOpen={isOpen}
        PrimaryButtonComponent={() => (
          <Button
            label={t('common-back')}
            fullWidth
            onClick={() => {
              handleClose();
            }}
          />
        )}
        onClose={handleClose}
        {...props}
      />
    </DynamicPortal>
  );
};
