import axios from 'axios';
import type {
  SelectArtistAccountPayload,
  SelectArtistAccountResponse,
  SignInResponse,
  SignOutResponse,
  UnselectArtistAccountResponse,
} from '@/apis/typed';

const API_ORIGIN = process.env.NEXT_PUBLIC_API_ORIGIN;

const axiosInstance = axios.create({
  baseURL: `${API_ORIGIN}/v1`,
  withCredentials: true,
});

let authHeaders = {};

const fetchAuthHeaders = async () => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/frontend-api/google-auth`, {
      cache: 'no-store',
    });
    authHeaders = await response.json();
  } catch (error) {
    console.error('Failed to fetch auth headers:', error);
    throw error;
  }
};

axiosInstance.interceptors.request.use(
  async (config) => {
    if (
      Object.keys(authHeaders).length === 0 &&
      process.env.NEXT_PUBLIC_ENABLE_IAM === 'true' &&
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
    ) {
      await fetchAuthHeaders();
    }

    // @ts-expect-error 型ERRORの解消
    config.headers = {
      ...config.headers,
      ...authHeaders,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const addAuthHeader = (token?: string) =>
  token ? { headers: { 'X-Klew-Authorization': `Bearer ${token}` } } : {};

export const PostSignInAPI = async (token?: string): Promise<SignInResponse> => {
  try {
    const res = await axiosInstance.post<SignInResponse>('signin', {}, addAuthHeader(token));
    return res.data;
  } catch (error) {
    console.error('SignIn API error:', error);
    throw error;
  }
};

export const PostSignOutAPI = async (token?: string): Promise<SignOutResponse> => {
  try {
    const res = await axiosInstance.post<SignOutResponse>('signout', {}, addAuthHeader(token));
    return res.data;
  } catch (error) {
    console.error('SignOut API error:', error);
    throw error;
  }
};

/**
 * アーティストオフィシャルアカウント選択APIを実行する
 *
 * TODO: 以下のドキュメントはエンドポイントが古いままのため、新しくなり次第リンクを更新する
 * @see https://kulture-klew-auth-openapi.vercel.app/#operation/post-v1-artist-user-select
 */
export const PostSelectArtistAccountAPI = async ({
  artistOfficialAccountId,
  channelId,
  token,
}: SelectArtistAccountPayload): Promise<SelectArtistAccountResponse> => {
  try {
    const res = await axiosInstance.post<SelectArtistAccountResponse>(
      'artist/official/select',
      {
        artistOfficialAccountId,
        channelId,
      },
      addAuthHeader(token),
    );
    return res.data;
  } catch (error) {
    console.error('Select Artist Account API error:', error);
    throw error;
  }
};

/**
 * アーティストオフィシャルアカウント選択解除APIを実行する
 *
 * TODO: 以下のドキュメントはエンドポイントが古いままのため、新しくなり次第リンクを更新する
 * @see https://kulture-klew-auth-openapi.vercel.app/#operation/post-v1-artist-user-unselect
 */
export const PostUnselectArtistAccountAPI = async (
  token?: string,
): Promise<UnselectArtistAccountResponse> => {
  try {
    const res = await axiosInstance.post<UnselectArtistAccountResponse>(
      'artist/official/unselect',
      {},
      addAuthHeader(token),
    );
    return res.data;
  } catch (error) {
    console.error('Unselect Artist Account API error:', error);
    throw error;
  }
};
