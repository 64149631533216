import clsx from 'clsx';
import React, { startTransition, useCallback, useEffect, useRef, useState } from 'react';
import { useProgress } from 'react-transition-progress';
import type { ModalBaseType } from './types';
import { Button } from '@/components/ui/button/Button';
import { IconClose } from '@/components/ui/icons/IconClose';
import { Z_INDEX } from '@/constants/z-index';
import { useRouter } from '@/i18n/routing';

export const ModalBase = ({
  isOpen = false,
  cancelable = false,
  title,
  body,
  children,
  PrimaryButtonComponent,
  secondaryButton,
  secondaryButtonDisabled = false,
  redirectUrl,
  onClose,
  fullScreen = false,
  withHeaderLayout = false,
  theme = 'light',
}: ModalBaseType) => {
  const appRouter = useRouter();

  const [isOverflow, setIsOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const startProgress = useProgress();

  useEffect(() => {
    if (!isOpen) return;

    const modalElement = contentRef.current;
    if (!modalElement) return;

    // TODO: no-scroll関連を一時的にコメントアウト
    // document.body.classList.add('no-scroll');

    const resizeObserver = new ResizeObserver(() => {
      setIsOverflow(window.innerHeight < modalElement.clientHeight);
    });

    resizeObserver.observe(modalElement);

    return () => {
      // TODO: no-scroll関連を一時的にコメントアウト
      // document.body.classList.remove('no-scroll');
      resizeObserver.disconnect();
    };
  }, [isOpen]);

  const handleClickSecondaryButton = useCallback(() => {
    if (redirectUrl) {
      startTransition(() => {
        startProgress();
      });
      appRouter.push(redirectUrl);
    }
    if (onClose) {
      onClose();
    }
  }, [appRouter.push, onClose, redirectUrl]);

  if (!isOpen) return null;

  return (
    <div
      className={`pointer-events-none fixed left-1/2 top-0 h-full w-full -translate-x-1/2 md:w-md-base lg:w-lg-base`}
      style={{ zIndex: Z_INDEX.MODAL_BASE }}
    >
      {(fullScreen || withHeaderLayout) && cancelable && (
        <Button
          variant='iconOnly'
          IconComponent={IconClose}
          color={theme === 'light' ? 'black' : 'white'}
          className='pointer-events-auto absolute right-2x top-3x z-30 h-5 w-5'
          onClick={onClose}
        />
      )}
      <div
        className={clsx('flex h-full', {
          'justify-center  overscroll-y-none': !fullScreen || !withHeaderLayout,
          'items-center': !isOverflow,
          'items-baseline': isOverflow,
        })}
      >
        {withHeaderLayout && <div className='hidden w-[20rem] shrink-0 lg:block' />}
        <div
          ref={contentRef}
          className={clsx('pointer-events-auto relative z-20 w-full', {
            'lg:max-w-[33.5rem]': !withHeaderLayout,
            'h-full overflow-y-auto': fullScreen || withHeaderLayout,
            'mx-5 rounded-[3.2rem] px-5 pb-[2rem] pt-[5rem]': !fullScreen && !withHeaderLayout,
            'my-5': (!fullScreen || !withHeaderLayout) && !isOverflow,
            'my-[6.8rem]': (!fullScreen || !withHeaderLayout) && isOverflow,
            'bg-white': theme === 'light',
            'bg-black': theme === 'dark',
            'bg-transparent': theme === 'transparent',
          })}
        >
          {title && (
            <div className='mb-[3rem] whitespace-pre-wrap text-center text-head-18'>{title}</div>
          )}

          <div className='whitespace-pre-wrap text-body-14 tracking-wider [&_a]:underline'>
            {body}
          </div>

          {children && children}
          {PrimaryButtonComponent && (
            <div className={`${children ? 'mx-2.5' : 'mt-[3rem]'} text-center`}>
              <PrimaryButtonComponent />
            </div>
          )}
          {secondaryButton && (
            <button
              className='mx-auto mt-[0.5rem] block min-w-[4.2rem] py-[1.5rem] text-center text-link-12 underline'
              onClick={handleClickSecondaryButton}
              disabled={secondaryButtonDisabled}
            >
              {secondaryButton}
            </button>
          )}
        </div>

        {!withHeaderLayout && (
          <div
            className={clsx('pointer-events-auto absolute left-0 top-0 z-0 h-full w-full', {
              'bg-bk-opacity-20': theme === 'light',
              'bg-bk-opacity-80': theme === 'dark',
            })}
            onClick={onClose}
          />
        )}
      </div>
    </div>
  );
};
