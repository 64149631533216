import { parseCookies, setCookie as nookiesSet } from 'nookies';
import type { CookieKeyType, FixedValueCookieType } from '@/constants/cookie';
import { FIXED_VALUE_COOKIE } from '@/constants/cookie';

export const getCookie = (keyType: CookieKeyType) => {
  const cookies = parseCookies();
  return cookies[keyType];
};

type OptionsType = {
  maxAge?: number;
  path?: string;
  secure?: boolean;
  sameSite?: string;
};

export const setCookie = (keyType: FixedValueCookieType, options?: OptionsType) => {
  const { KEY, VALUE } = FIXED_VALUE_COOKIE[keyType];
  nookiesSet(null, KEY, VALUE, options);
};
